import {
  addDoc,
  collection, doc, getDoc, getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { isUndefined, isNull, isObject } from 'lodash-es';
import { db } from '../../../config/firebase';
import { uploadFile } from '../storage';
// eslint-disable-next-line import/no-cycle
import { getCrewListByVesselId } from './user';
import { createNewVesselSql, updateVesselSql } from '../../azure/vessel';

const collectionName = 'vessels';
const vesselsCollRef = collection(db, collectionName);

const companyCollRef = collection(db, 'company');

export const fetchAllVessels = async (role, vesselId, companyId) => {
  if (isUndefined(vesselId) || isNull(vesselId)) {
    return [];
  }

  if (isUndefined(companyId) || isNull(companyId)) {
    return [];
  }

  if (isUndefined(role) || isNull(role)) {
    return [];
  }

  let whereQuery = where('id', '==', vesselId);

  if (role === 'SUPER_ADMIN') {
    whereQuery = null;
  } else if (role === 'COMPANY_ADMIN' || role === 'COMPANY_OWNER' || role === 'CAPTAIN') {
    whereQuery = where('companyId', '==', companyId);
  }

  const constraints = [whereQuery];

  const companiesQuery = query(vesselsCollRef, ...constraints);

  const snap = await getDocs(companiesQuery);

  const vessels = [];

  snap.forEach((v) => {
    const data = v.data();
    const { id } = v;

    const vessel = { id, ...data };

    vessels.push(vessel);
  });

  return vessels;
};

export const fetchVesselById = async ({ vesselId }) => {
  if (isNull(vesselId) || isUndefined(vesselId)) {
    return { data: null, id: null, crewList: [] };
  }

  const vesselRef = doc(db, collectionName, vesselId);
  const snap = await getDoc(vesselRef);

  const data = snap.data();

  const { result } = await getCrewListByVesselId({ vesselId });

  return { ...data, id: vesselId, crewList: result };
};

export const updateVessel = async ({ data, vesselId }) => {
  const vesselRef = doc(db, collectionName, vesselId);
  const lastUpdateAt = new Date().toISOString();

  await setDoc(vesselRef, { ...data, lastUpdateAt }, { merge: true });
  await updateVesselSql({ id: vesselId, ...data, lastUpdateAt });
};

export const updateCompanyVessels = async ({ companyId, vesselId }) => {
  const companyRef = doc(db, 'company', companyId);
  const companySnap = await getDoc(companyRef);
  const companyData = companySnap.data();

  const vessels = companyData?.vessels || [];

  vessels.push(vesselId);

  await setDoc(companyRef, { vessels }, { merge: true });
};

export const createVessel = async (data) => {
  try {
    const createdAt = new Date().toISOString();

    const { image, companyId } = data;

    const { id } = await addDoc(vesselsCollRef, {
      ...data, image: null, documents: [], createdAt,
    });

    if (!isNull(image) && isObject(image)) {
      // eslint-disable-next-line no-param-reassign
      data.image = await uploadFile({ file: image, path: `vessels/${id}` });
    }

    await updateVessel({ data: { id, image: data?.image }, vesselId: id });
    await updateCompanyVessels({ companyId, vesselId: id });

    await createNewVesselSql({
      ...data, company: { id: companyId }, createdAt, vesselId: id,
    });

    return { id };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error creating vessel: ', err);
    throw err;
  }
};

// eslint-disable-next-line no-unused-vars
export const fetchVesselsByCompanyId = async ({ companyId }) => {
  const constraints = [];

  constraints.push(where('id', '==', companyId));

  const queryBuild = query(companyCollRef, ...constraints);

  const snapshots = await getDocs(queryBuild);

  const results = [];

  snapshots.forEach((s) => {
    const data = s.data();
    const { id } = s;

    const result = { ...data, id };

    results.push(result);
  });

  return { result: results };
};
