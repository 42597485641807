/* eslint-disable import/prefer-default-export */
import API from './_instance';
import { endpoints } from './constants';

// TODO: Error handling / data validation
export const fetchCategories = async ({
  vesselId,
}) => {
  if (!vesselId) {
    return [];
  }

  const { data } = await API.get(`${endpoints.cms}/${vesselId}`);

  return data;
};

export const saveDraft = async ({
  vesselId,
  checksum,
  fileName,
  path,
  version,
  documentId,
  documentTitle,
}) => {
  const { data } = await API.put(`${endpoints.cms}/${vesselId}`, {
    checksum,
    fileName,
    path,
    version,
    documentId,
    documentTitle,
  });

  return data;
};

export const publishContent = async ({
  vesselId,
  documentId,
  documentTitle,
  url,
}) => {
  const { data } = await API.put(`${endpoints.cms}/${vesselId}/publish`, {
    documentId,
    documentTitle,
    url,
  });

  return data;
};

export const deleteDocument = async ({
  documentId,
}) => {
  const response = await API.delete(`${endpoints.cms}/${documentId}`);

  return response;
};

export const deleteCategory = async ({
  categoryId,
}) => {
  const response = await API.delete(`${endpoints.cms}/category/${categoryId}`);

  return response;
};

export const createDocument = async ({
  fileName,
  description,
  subCategoryId,
  vesselId,
}) => {
  const response = await API.post(`${endpoints.cms}`, {
    title: fileName,
    description,
    subCategoryId,
    vesselId,
  });

  return response;
};

export const createCategory = async ({
  categoryName,
  description,
  vesselId,
}) => {
  const response = await API.post(`${endpoints.cms}/category`, {
    title: categoryName,
    description,
    vesselId,
  });

  return response;
};

export const updateCategory = async ({
  categoryName,
  description,
  id,
  vesselId,
}) => {
  const response = await API.put(`${endpoints.cms}/category`, {
    title: categoryName,
    description,
    id,
    vesselId,
  });

  return response;
};

export const updateCategoryAccess = async ({
  categoryId,
  vessels,
}) => {
  const response = await API.patch(`${endpoints.cms}/category`, {
    categoryId,
    vessels,
  });

  return response;
};

export const importHandbook = async ({
  vesselId,
  companyId,
}) => {
  const response = await API.post(`${endpoints.cms}/import`, {
    vesselId,
    companyId,
  });

  return response;
};
