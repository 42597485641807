import PropTypes from 'prop-types';
import { Center, Spinner } from '@chakra-ui/react';
import { IntlProvider } from 'react-intl';
import { useLocale } from './hooks';

function AppSettingsProvider({ children }) {
  const { isLoading: isFetchingTranslations, data: translations, locale } = useLocale();

  const isLoading = isFetchingTranslations;

  return (
    <>
      {isLoading && (
        <Center width="100vw" height="100vh">
          <Spinner />
        </Center>
      )}
      {!isLoading && (
        <IntlProvider
          defaultLocale={locale}
          locale={locale}
          messages={translations}
          key={locale}
        >
          {children}
        </IntlProvider>
      )}
    </>
  );
}

AppSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppSettingsProvider;
