import { useEffect } from 'react';
import { auth, onAuthStateChanged } from '../config/firebase';
import { authStore } from '../store/auth';
import { userStore } from '../store/user';

const useRefreshToken = () => {
  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        const tokenResult = await user.getIdTokenResult();
        const tokenExpirationTime = new Date(tokenResult.expirationTime).getTime();

        // Refresh if token is about to expire in the next 5 minutes
        if (tokenExpirationTime - Date.now() <= 5 * 60 * 1000) {
          try {
            const freshTokenResult = await user.getIdTokenResult(true);
            // Update auth store with fresh token
            authStore.setState({
              accessToken: freshTokenResult.token,
              tokenExpirationTime,
            });
          } catch (error) {
            console.error('Error refreshing token:', error);
          }
        } else {
          // Token is still valid, update auth store
          authStore.setState({
            accessToken: tokenResult.token,
            tokenExpirationTime,
          });
        }
      } else {
        authStore.getState().handleLogout();
        userStore.getState().resetUser();
      }
    });

    return () => unsubscribe();
  }, []);
};

export default useRefreshToken;
