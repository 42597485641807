import { VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import ImagePreview from './parts/ImagePreview';

export const UPLOAD_COMPONENT_NAME_PREFIX = 'upload_';

function ImageUpload({ name: nameFromJson }) {
  const name = `${UPLOAD_COMPONENT_NAME_PREFIX}${nameFromJson}`;

  const { getValues } = useFormContext();

  const images = getValues(name);

  return (
    <VStack marginY="8px">
      {images?.map((imgUrl, index) => (
        <ImagePreview
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          url={imgUrl}
          index={index}
        />
      ))}
    </VStack>
  );
}

ImageUpload.propTypes = {
  name: PropTypes.string,
};

ImageUpload.defaultProps = {
  name: 'images',
};

export default ImageUpload;
