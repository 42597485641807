/* eslint-disable arrow-body-style */
/* eslint-disable new-cap */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const delay = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });

export const removeWhiteSpaces = (string) => string.replace(/ +/g, '_');

export const getFileExtensionFromUrl = (url) => url?.split(/[#?]/)[0]?.split('.').pop().trim();

export const getFileNameFromUrl = (url) => url?.split('/').pop()?.split('#')[0]?.split('?')[0]?.replace(/\.[^/.]+$/, '');

export const helperFunction = (dateString) => {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const TruncateText = ({ text, length }) => {
  if (!text) return '';
  if (text.length <= length) return text;

  return `${text.substring(0, length)}...`;
};

// eslint-disable-next-line arrow-body-style
export const formatDate = (submittedAt) => {
  // eslint-disable-next-line new-cap
  return new dayjs.unix(submittedAt).format('DD.MM.YYYY HH:mm');
};
// eslint-disable-next-line arrow-body-style
export const formatDateOnly = (submittedAt) => {
  return new dayjs.unix(submittedAt).format('DD.MM.YYYY');
};
export const formatTimeOnly = (submittedAt) => {
  return new dayjs.unix(submittedAt).format('HH:mm');
};
export const generateRandomString = ({ length = 6 }) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random()
      * charactersLength));
  }
  return result;
};
export const developerIds = ['0iRvQeopWASlOj0n0U9q1HbatF72', 'l41JxTCujtgmuIyA4Wbx0EOS1P62', 'djNKpNeY3VfAKQo2BTMi7XBsBhS2'];
