import PropTypes from 'prop-types';
import { HStack } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa';

// eslint-disable-next-line no-unused-vars
function DocumentLink({
  // eslint-disable-next-line no-unused-vars
  children, stack, document,
}) {
  return (
    <HStack space="16px">
      {children}
      <FaChevronRight />
    </HStack>
  );
}

DocumentLink.propTypes = {
  children: PropTypes.node.isRequired,
  document: PropTypes.string.isRequired,
  stack: PropTypes.string.isRequired,
};

export default DocumentLink;
