/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import {
  HStack, Icon, Text, VStack, Link,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useActiveMenuProperties } from '../hooks';
import SubMenuItem from './SubMenuItem';

function MenuItem({
  path, label, icon, submenu, onToggle,
}) {
  const { isGroupActive, isItemActive } = useActiveMenuProperties();

  return (
    <VStack
      alignItems="stretch"
      spacing={0}
      position="relative"
      backgroundColor={isGroupActive ? 'gray.50' : 'white'}
      _after={{
        textDecoration: 'none',
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        width: '4px',
        backgroundColor: isGroupActive ? 'gray.600' : 'transparent',
      }}
    >
      <Link
        as={NavLink}
        onClick={onToggle}
        to={path}
        backgroundColor={isItemActive && 'gray.200'}
        padding={[3, 4]}
        width="100%"
        _hover={{
          backgroundColor: isItemActive ? 'gray.200' : 'gray.100',
        }}
      >
        <HStack spacing="24px">
          <Icon as={icon} color="blue.500" />
          <Text color="black">{label}</Text>
        </HStack>
      </Link>
      {submenu && isGroupActive && (
        <VStack>
          {submenu.map((item) => (
            <SubMenuItem
              key={item?.path}
              {...item}
              onToggle={onToggle}
            />
          ))}
        </VStack>
      )}
    </VStack>
  );
}

MenuItem.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  // eslint-disable-next-line react/forbid-prop-types
  submenu: PropTypes.array,
  onToggle: PropTypes.func.isRequired,
};

MenuItem.defaultProps = {
  path: undefined,
  label: undefined,
  icon: () => { },
  submenu: undefined,
};

export default MenuItem;
