/* eslint-disable no-unused-vars */
import {
  collection, doc, getDoc, getDocs, limit,
  orderBy as orderByFirebase, query, setDoc, startAfter, where, deleteDoc, deleteField, addDoc,
} from 'firebase/firestore';
import { isUndefined, isNull, isObject } from 'lodash-es';
import { db, auth } from '../../../config/firebase';
// eslint-disable-next-line import/no-cycle
import { fetchAllVessels, fetchVesselsByCompanyId } from './vessel';
import { fetchAllCompanies } from './company';
import { uploadFile } from '../storage';
// eslint-disable-next-line import/no-cycle
import { createUser } from '../auth';
import { createNewUserSql, updateUserSql } from '../../azure/api';

const usersCollRef = collection(db, 'users');

export const getUserByUid = async ({ uid }) => {
  if (!uid) return null;
  const requestedUid = uid;
  const userRef = doc(db, 'users', uid); // get user by uid

  const response = await getDoc(userRef); // get user data

  const data = response.data();
  const requestingUid = data.id;

  return data;
};

/**
 * querying by
 * @param {String} vesselId
 * @param {String} companyId
 * order by
 * @param {String} orderBy - ordering param, default: createdAt
 * @param {String} direction - default: desc
 * control pagination by
 * @param {Number} cursor - represents mapping page 2 cursor - need to fetch tasks for certain page
 * @param {Number} itemsPerPage
*/

export const fetchUsersTable = async ({
  // eslint-disable-next-line no-unused-vars
  vesselId = undefined, companyId = undefined, orderBy = 'createdAt', isAscending = false, itemsPerPage = 25, cursor = undefined,
}) => {
  const direction = isAscending ? 'asc' : 'desc';

  const order = orderByFirebase(orderBy, direction);

  const constraints = [order, limit(itemsPerPage)];

  if (!isUndefined(cursor)) {
    constraints.push(startAfter(cursor));
  }

  const usersQuery = query(usersCollRef, ...constraints);

  const snap = await getDocs(usersQuery);

  const users = [];

  const companies = await fetchAllCompanies();
  const vessels = await fetchAllVessels();

  snap.forEach((d) => {
    const data = d.data();
    const { id } = d;
    const vessel = vessels.find((v) => v.id === data?.vesselId);
    const company = companies.find((c) => c.id === vessel?.companyId);

    const user = {
      id, company, vessel, ...data,
    };

    users.push(user);
  });

  return { users, docs: snap.docs };
};

export const fetchAllUsers = async (role, vesselId, companyId) => {
  if (isUndefined(vesselId) || isNull(vesselId) || isUndefined(companyId) || isNull(companyId) || isUndefined(role) || isNull(role)) {
    return [];
  }

  let whereQuery = where('vesselId', '==', vesselId);

  if (role === 'SUPER_ADMIN') {
    whereQuery = null;
  } else if (role === 'COMPANY_ADMIN' || role === 'COMPANY_OWNER') {
    whereQuery = where('companyId', '==', companyId);
  }

  const constraints = [whereQuery];

  const usersQuery = query(usersCollRef, ...constraints);

  const snap = await getDocs(usersQuery);

  const users = [];

  snap.forEach((d) => {
    const data = d.data();
    const { id } = d;

    const user = {
      id, ...data,
    };

    users.push(user);
  });

  return users;
};

export const fetchCompanyUsers = async (companyId) => {
  if (isUndefined(companyId) || isNull(companyId)) {
    return [];
  }

  const constraints = [
    where('companyId', '==', companyId),
  ];

  const usersQuery = query(usersCollRef, ...constraints);

  const snap = await getDocs(usersQuery);

  const users = [];

  snap.forEach((d) => {
    const data = d.data();
    const { id } = d;

    const user = {
      id, ...data,
    };

    users.push(user);
  });

  return users;
};

export const fetchUsersCount = async () => {
  const snaps = await getDocs(usersCollRef);
  const count = snaps.docs.length;
  return count;
};

export const createNewUser = async ({
  email, password, ...restProps
}) => {
  const createdAt = new Date().toISOString();
  // The API will handle FB related actions as well, refer to that code for more info..
  const result = await createNewUserSql({
    ...restProps, email, results: [], fcmTokens: [], password, createdAt,
  });

  return result;
};

export const updateUser = async ({
  // eslint-disable-next-line no-unused-vars
  uid, data,
}) => {
  const userRef = doc(db, 'users', uid);

  await setDoc(userRef, { ...data }, { merge: true });

  const updatedUser = await updateUserSql({ ...data, id: uid });
};

const updateHealthCertificate = async (data, targetId) => {
  const userRef = doc(db, 'users', targetId);

  const newData = {
    image: data?.image,
    validFrom: data?.validFrom,
    validTo: data?.validTo,
  };

  await setDoc(userRef, { health: { ...newData } }, { merge: true });
};

const updateUserReEdCollection = async (data, targetId, documentId) => {
  const userRef = doc(db, 'users', targetId);
  const collectionRef = collection(userRef, 'reeducation');
  const docRef = doc(collectionRef, documentId);

  const newData = {
    image: data?.image,
    educationName: data?.educationName,
    validFrom: data?.validFrom,
    validTo: data?.validTo,
  };

  await setDoc(docRef, { ...newData }, { merge: true });
};

const updateUserRightsCollection = async (data, targetId, documentId) => {
  const userRef = doc(db, 'users', targetId);
  const collectionRef = collection(userRef, 'employerRights');
  const docRef = doc(collectionRef, documentId);

  const newData = {
    image: data?.image,
    rightsName: data?.educationName,
    validFrom: data?.validFrom,
    validTo: data?.validTo,
  };

  await setDoc(docRef, { ...newData }, { merge: true });
};

export const updateCertificate = async ({
  id, type, targetId, updatedData, documentId,
}) => {
  const uploadedImage = updatedData?.image;
  if (!isNull(uploadedImage) && isObject(uploadedImage)) {
    // eslint-disable-next-line no-param-reassign
    updatedData.image = await uploadFile({ file: uploadedImage, path: `certificates/${id}` });
  }

  switch (type) {
    case 'health':
      await updateHealthCertificate(updatedData, targetId);
      break;
    case 'reeducation':
      if (documentId && documentId !== '') {
        await updateUserReEdCollection(updatedData, targetId, documentId);
      } else {
        throw new Error('error/invalid-document-id');
      }
      break;
    case 'rights':
      if (documentId && documentId !== '') {
        await updateUserRightsCollection(updatedData, targetId, documentId);
      } else {
        throw new Error('error/invalid-document-id');
      }
      break;
    default:
      throw new Error('error/invalid-option');
  }
};

export const deleteUserCertificate = async ({ uid, type, documentId }) => {
  const userRef = doc(db, 'users', uid);
  let collectionRef;

  switch (type) {
    case 'health':
      await setDoc(userRef, { health: deleteField() }, { merge: true });
      break;
    case 'reeducation':
      collectionRef = collection(userRef, 'reeducation');
      break;
    case 'rights':
      collectionRef = collection(userRef, 'employerRights');
      break;
    default:
      throw new Error('error/invalid-option');
  }

  if (collectionRef) {
    const docRef = doc(collectionRef, documentId);
    await deleteDoc(docRef);
  }
};

export const addCertificate = async ({ uid, type, data }) => {
  const userRef = doc(db, 'users', uid);

  const newCertificateData = {
    ...data,
  };

  if (data?.image) {
    newCertificateData.image = await uploadFile({ file: data?.image, path: `certificates/${uid}` });
  }

  try {
    switch (type) {
      case 'health':
        await setDoc(userRef, { health: newCertificateData }, { merge: true });
        break;
      case 'Reeducation':
        await addDoc(collection(userRef, 'reeducation'), newCertificateData);
        break;
      case 'Employee Rights':
        await addDoc(collection(userRef, 'employerRights'), newCertificateData);
        break;
      default:
        throw new Error('error/invalid-option');
    }
  } catch (error) {
    console.error('Error adding new certificate: ', error);
  }
};

export const switchUserActiveStatus = async ({ id, isActive }) => {
  const userRef = doc(db, 'users', id);

  await setDoc(userRef, { isActive }, { merge: true });
};

export const getCrewListByVesselId = async ({ vesselId }) => {
  const constraints = [];

  constraints.push(where('vesselId', '==', vesselId));

  const queryBuild = query(usersCollRef, ...constraints);

  const snapshots = await getDocs(queryBuild);

  const results = [];

  snapshots.forEach((s) => {
    const data = s.data();
    const { id } = s;

    const result = { ...data, id };

    results.push(result);
  });

  return { result: results };
};

export const fetchUserCollection = async ({
  userId, collectionName,
}) => {
  const userRef = doc(db, 'users', userId);

  const collectionRef = collection(userRef, collectionName);

  const snapshots = await getDocs(collectionRef);

  const results = [];

  snapshots.forEach((s) => {
    const data = s.data();
    const { id } = s;

    const result = { ...data, id };

    results.push(result);
  });

  return results;
};

export const getUserCertificates = async ({
  uid,
}) => {
  const userRef = doc(db, 'users', uid);
  const response = await getDoc(userRef);
  const data = response.data();

  const employerRightsRef = collection(userRef, 'employerRights');
  const reeducationRef = collection(userRef, 'reeducation');

  const employerRightsSnap = await getDocs(employerRightsRef);
  const reeducationSnap = await getDocs(reeducationRef);

  const results = {
    health: data?.health,
    rights: employerRightsSnap.docs.map((d) => ({ ...d.data(), id: d.id })),
    reeducation: reeducationSnap.docs.map((d) => ({ ...d.data(), id: d.id })),
  };

  return results;
};

export const fetchUsersByCompanyId = async ({ companyId }) => {
  const { result: vessels } = await fetchVesselsByCompanyId({ companyId });
  const promises = vessels.map((v) => getCrewListByVesselId({ vesselId: v?.id }));
  const vesselCrews = await Promise.all(promises);

  let users = [];
  // eslint-disable-next-line array-callback-return
  vesselCrews.map((crew) => {
    users = users.concat(crew.result);
  });

  return { result: users };
};

// function to get the users companyId
export const getCompanyId = async (user) => {
  if (user.companyId) {
    return user.companyId;
  }
  const { vesselId } = user;
  if (!vesselId) {
    return null;
  }

  const vesselRef = doc(db, 'vessels', vesselId);

  const vesselSnap = await getDoc(vesselRef);

  if (!vesselSnap.exists()) {
    return null;
  }

  const vesselData = vesselSnap.data();
  const companyId = vesselData?.companyId;

  return companyId;
};
