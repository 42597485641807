import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, HStack, Text, Divider,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { isArray, isEmpty, isUndefined } from 'lodash-es';
import { useIntl } from 'react-intl';
import { useCreateElementFromJson } from '../../providers/FormProvider/FormProvider';
import styles from './Checkbox.module.scss';

function Radio({
  name, label, required, items, horizontal, labelProps,
}) {
  const intl = useIntl();
  const requiredErrMessage = intl.formatMessage({ id: 'ERR_FIELD_REQUIRED', defaultMessage: 'This field is required.' });
  const { formState: { errors }, getValues } = useFormContext();

  // at the moment those inputs will always be disabled by default
  // || false - initially
  const isInputDisabled = getValues('formDisabled') || true;

  const [selected, setSelected] = useState(null);
  const [containsAttentionItem, setContainsAttentionItem] = useState(false);

  useEffect(() => {
    if (isInputDisabled) {
      const val = getValues(name);
      const findItemWithValue = items.find((i) => i.value === val);
      const children = findItemWithValue?.children;
      if (children) {
        setSelected(findItemWithValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createElementFromJson = useCreateElementFromJson();

  return (
    <>
      {!isEmpty(label) && <Text variant="label" marginBottom="16px" {...labelProps}>{label}</Text>}
      {horizontal && (
        <>
          <HStack justifyContent="space-between" width="300px">
            <Controller
              defaultValue={false}
              name={name}
              rules={{ required }}
              render={({
                field: {
                  onChange, value: currentValue,
                },
              }) => items.map((item, index) => {
                const { text, value } = item;
                const isLastItem = index === (items.length - 1);

                const needsAttention = isLastItem && value === currentValue;

                if (needsAttention) {
                  setContainsAttentionItem(true);
                }

                return (
                  <HStack
                    id={name}
                    space="8px"
                    marginBottom={isLastItem ? 0 : '0'}
                    className={needsAttention ? styles.attention : null}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <Checkbox
                      isReadOnly
                      key={name}
                      id={name}
                      // _icon={{ size: '18px' }}
                      _disabled={{
                        _checked: {
                          '& .chakra-checkbox__control': { background: isLastItem ? '#c25757' : '#61a158', borderColor: isLastItem ? '#c25757' : '#61a158' },
                        },
                      }}
                      _checked={{
                        '& .chakra-checkbox__control': { background: isLastItem ? '#c25757' : '#61a158', borderColor: isLastItem ? '#c25757' : '#61a158' },
                      }}
                      size="lg"
                      iconColor="white"
                      colorScheme="primary"
                      value={value}
                      isChecked={currentValue === value}
                      onChange={() => {
                        onChange(value);
                        setSelected(item);
                      }}
                      isDisabled={isInputDisabled}
                    />
                    <Text fontSize="16px">{text}</Text>
                  </HStack>
                );
              })}
            />
          </HStack>
          {!containsAttentionItem && (
            <Divider sx={{ marginBottom: '20px' }} />
          )}
        </>
      )}
      {!horizontal
        && (
          <Controller
            defaultValue={false}
            name={name}
            rules={{ required }}
            render={({
              field: {
                onChange, value: currentValue,
              },
            }) => items.map(({ text, value }, index) => {
              const isLastItem = index === (items.length - 1);
              return (
                <HStack
                  id={name}
                  space="8px"
                  alignItems="center"
                  marginBottom={isLastItem ? 0 : '12px'}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <Checkbox
                    key={name}
                    id={name}
                    _icon={{ size: '18px' }}
                    _checked={{
                      borderColor: 'primary.500',
                      backgroundColor: 'primary.500',
                    }}
                    borderColor="black"
                    boxSize="24px"
                    value={value}
                    isChecked={currentValue === value}
                    isDisabled={isInputDisabled}
                    onChange={() => {
                      onChange(value);
                    }}
                  />
                  <Text fontSize="16px">{text}</Text>
                </HStack>
              );
            })}
          />
        )}
      {errors[name] && <Text variant="error">{requiredErrMessage}</Text>}
      {!isUndefined(selected?.children) && (
        isArray(selected?.children)
          ? selected?.children?.map((child) => createElementFromJson(child))
          : createElementFromJson(selected?.children)
      )}
    </>

  );
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  required: PropTypes.bool,
  horizontal: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  labelProps: PropTypes.object,
};

Radio.defaultProps = {
  label: null,
  required: false,
  horizontal: false,
  labelProps: {},
};

export default Radio;
