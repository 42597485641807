import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';
import { hiddenMenuRoutes } from './config';
import { getUserByUid } from '../../services/firebase/firestore/user';
import { useAuthStore } from '../../store/auth';
import { useUserStore } from '../../store/user';
import useToast from '../../hooks/useToast';
import messages from './messages';

// eslint-disable-next-line import/prefer-default-export
export const useHideMenu = () => {
  const { pathname } = useLocation();
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    if (hiddenMenuRoutes.includes(pathname)) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [pathname]);

  return isHidden;
};

export const useActiveMenuProperties = (path) => {
  const { pathname } = useLocation();

  const [state, setState] = useState({
    activeGroup: undefined,
    activeItem: undefined,
    isGroupActive: false,
    isItemActive: false,
  });

  useEffect(() => {
    const splittedPathname = pathname.split('/');
    const activeGroup = `/${splittedPathname[1]}`;

    setState({
      isGroupActive: path === activeGroup,
      isItemActive: path === pathname,
    });
  }, [pathname, path]);

  return state;
};

export const useGetUser = () => {
  const { uid } = useAuthStore();
  const { setUser } = useUserStore();

  const intl = useIntl();
  const toast = useToast();

  const { isLoading } = useQuery(
    ['user', uid],
    () => getUserByUid({ uid }),
    {
      onSuccess: (user) => {
        setUser(user);
      },
      onError: (err) => {
        toast({
          title: intl.formatMessage(messages.errorOnFetchingUser),
          description: err.toString(),
        });
      },
    },
  );

  return {
    isLoading,
  };
};
