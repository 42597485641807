import {
  collection, doc, getDoc, getDocs, setDoc, addDoc, deleteDoc, where, query,
} from 'firebase/firestore';
import { isNull } from 'lodash-es';
import { MD5 } from 'crypto-js';
import { db } from '../../../config/firebase';
// eslint-disable-next-line import/no-cycle
import { uploadFile, uploadPdf, getFileByPath } from '../storage';
import { saveDraft, publishContent as publishContentAPIAction } from '../../azure/cms';
import { convertHtmlToPdf } from '../../../utils/pdf';

const collectionName = 'document';

const docCollRef = collection(db, collectionName);

// eslint-disable-next-line import/prefer-default-export
export const fetchDocuments = async () => {
  const snap = await getDocs(docCollRef);

  const documents = [];

  snap.forEach((d) => {
    const data = d.data();
    const { id } = d;

    const document = {
      id, ...data,
    };

    documents.push(document);
  });
};

export const fetchDocumentsByVessel = async (vesselId) => {
  const constraints = [];

  constraints.push(where('vessels', 'array-contains', vesselId));

  const queryBuild = query(docCollRef, ...constraints);

  const snap = await getDocs(queryBuild);

  const documents = [];

  snap.forEach((d) => {
    const data = d.data();
    const { id } = d;

    const document = {
      id, ...data,
    };

    documents.push(document);
  });

  return documents;
};

export const fetchDocumentById = async ({ documentId }) => {
  const docRef = doc(db, collectionName, documentId);
  const snap = await getDoc(docRef);

  const data = snap.data();

  return data;
};

export const updateDocument = async ({ data, documentId }) => {
  const docRef = doc(db, collectionName, documentId);

  const lastUpdateAt = new Date().toISOString();

  await setDoc(docRef, { ...data, lastUpdateAt }, { merge: true });
};

export const deleteFileFromDocument = async ({ file, documentId }) => {
  let documentData = await fetchDocumentById({ documentId });
  const { files } = documentData;

  const filterFiles = files.filter((f) => f !== file);

  await updateDocument({ data: { files: filterFiles }, documentId });

  documentData = await fetchDocumentById({ documentId });

  return documentData;
};

export const createDocument = async (data) => {
  const coll = collection(db, collectionName);
  const createdAt = new Date().toISOString();

  const { thumbnailUrl, ...restData } = data;
  const { id } = await addDoc(coll, { ...restData, thumbnailUrl: null, createdAt });

  if (!isNull(thumbnailUrl)) {
    // eslint-disable-next-line no-param-reassign
    data.thumbnailUrl = await uploadFile({ file: thumbnailUrl, path: `documents/${id}` });
  }

  await updateDocument({ data: { id, thumbnailUrl: data.thumbnailUrl }, documentId: id });

  return { id };
};

export const deleteDocument = async ({ documentId }) => {
  const docRef = doc(db, collectionName, documentId);
  await deleteDoc(docRef);
};

export const saveContentDraft = async (html, vesselId, documentId, documentTitle) => {
  try {
    const docRef = doc(db, 'cms_documents', documentId);
    const snap = await getDoc(docRef);

    const data = snap.data();

    const revisions = data?.revisions || [];
    let newVersion = 1;

    const newChecksum = MD5(html).toString();

    if (revisions && revisions.length > 0) {
      newVersion = revisions[0].version + 1;

      const previousDraft = revisions?.[0];

      if (previousDraft && previousDraft.checksum === newChecksum && data?.title === documentTitle) {
        // No changes
        return;
      }
    }

    const htmlFile = new Blob([html], { type: 'text/html' });

    const storagePath = `cms/${data.parentDocId}/${documentId}`;
    const storageFileName = `draft-${newVersion}.html`;

    await uploadPdf({ file: htmlFile, path: storagePath, fileName: storageFileName });

    await saveDraft({
      vesselId,
      checksum: newChecksum,
      path: storagePath,
      fileName: storageFileName,
      version: newVersion,
      documentId,
      documentTitle,
    });
  } catch (e) {
    throw new Error('error/saving-draft');
  }
};

export const publishContent = async (html, vesselId, documentId) => {
  try {
    // Convert to HTML
    await convertHtmlToPdf(html, async (pdf) => {
      const docRef = doc(db, 'cms_documents', documentId);
      const snap = await getDoc(docRef);

      const data = snap.data();

      const revisions = data?.revisions || [];
      const version = revisions[0]?.version || 1;
      const parentDocId = data?.parentDocId;

      const url = await uploadPdf({
        file: new Blob([pdf]),
        fileName: `published-${version}.pdf`,
        path: `cms/${parentDocId}/${documentId}`,
      });

      await uploadPdf({
        file: new Blob([html], { type: 'text/html' }),
        fileName: `published-${version}.html`,
        path: `cms/${parentDocId}/${documentId}`,
      });

      if (url) {
        await publishContentAPIAction({
          vesselId,
          documentId,
          url,
        });
      } else {
        throw new Error('error/uploading-pdf');
      }
    });
  } catch (e) {
    throw new Error(e);
  }
};

export const loadContentDraft = async ({ path, fileName }) => {
  try {
    const html = await getFileByPath({ path, fileName });

    return html;
  } catch (e) {
    throw new Error('error/loading-draft');
  }
};
