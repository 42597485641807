import { defineMessages } from 'react-intl';

const messages = defineMessages({
  error: {
    id: 'ERROR_GENERAL',
    defaultMessage: 'Something went wrong..',
  },
  tryAgain: {
    id: 'ERROR_TRY_AGAIN',
    defaultMessage: 'Try again',
  },
});

export default messages;
