/* eslint-disable new-cap */
/* eslint-disable import/prefer-default-export */
// import html2pdf from 'html2pdf.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import convertPdfMake from './convertPdfMake';

const fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf',
  },
};

const processHtml = (html) => {
  let modifiedHtml = html.replace(/font-family:\s*[^;]+/g, '');
  modifiedHtml = modifiedHtml.replace(/line-height:\s*[^;]+/g, '');
  return modifiedHtml;
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = fonts;

export const convertHtmlToPdf = async (html, callbackFunction) => {
  try {
    const completeHtml = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
            }

            .custom-style {
              color: red;
            }

            h1 {
              font-size: 2em !important;
              font-weight: bold;
              margin-bottom: 10px;
            }

            h2 {
              font-size: 1.5em !important;
              font-weight: bold;
              margin-bottom: 10px;
            }

            h3 {
              font-size: 1.3em !important;
              font-weight: bold;
              margin-bottom: 10px;
            }

            h4 {
              font-size: 1.2em !important;
              font-weight: bold;
              margin-bottom: 10px;
            }

            h5 {
              font-size: 1.0em !important;
              font-weight: bold;
              margin-bottom: 10px;
            }

            h6 {
              font-size: 0.9em !important;
              font-weight: bold;
              margin-bottom: 10px;
            }
            img.image_resized {
              height: auto;
            }
            .image {
              &.image-style-block-align-left,
              &.image-style-block-align-right {
                max-width: calc(100% - var(--ck-image-style-spacing));
              }

              &.image-style-align-left,
              &.image-style-align-right {
                clear: none;
              }

              &.image-style-side {
                float: right;
                margin-left: var(--ck-image-style-spacing);
                max-width: 50%;
              }

              &.image-style-align-left {
                float: left;
                margin-right: var(--ck-image-style-spacing);
              }

              &.image-style-align-right {
                float: right;
                margin-left: var(--ck-image-style-spacing);
              }

              &.image-style-block-align-right {
                margin-right: 0;
                margin-left: auto;
              }

              &.image-style-block-align-left {
                margin-left: 0;
                margin-right: auto;
              }
            }

            & .image-style-align-center {
              margin-left: auto;
              margin-right: auto;
            }

            & .image-style-align-left {
              float: left;
              margin-right: var(--ck-image-style-spacing);
            }

            & .image-style-align-right {
              float: right;
              margin-left: var(--ck-image-style-spacing);
            }

            & p + .image.image-style-align-left,
            & p + .image.image-style-align-right,
            & p + .image.image-style-side {
              margin-top: 0;
            }

            & .image-inline {
              &.image-style-align-left,
              &.image-style-align-right {
                margin-top: var(--ck-inline-image-style-spacing);
                margin-bottom: var(--ck-inline-image-style-spacing);
              }

              &.image-style-align-left {
                margin-right: var(--ck-inline-image-style-spacing);
              }

              &.image-style-align-right {
                margin-left: var(--ck-inline-image-style-spacing);
              }
            }

            .image.image_resized {
              max-width: 100%;
              display: block;
              box-sizing: border-box;

              & img {
                width: 100%;
              }

              & > figcaption {
                display: block;
              }
            }
          </style>
        </head>
        <body>
          ${html}
        </body>
      </html>
    `;

    const modifiedHtml = processHtml(completeHtml);

    // Convert the HTML in to a pdfmake format and set some default styles.
    const htmlConverted = convertPdfMake(modifiedHtml);

    const docDefinition = {
      content: [
        ...htmlConverted,
      ],
    };

    pdfMake.createPdf(docDefinition).getBuffer((buffer) => {
      callbackFunction(buffer);
    });
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};
