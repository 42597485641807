import PropTypes from 'prop-types';
import {
  Text, HStack,
} from '@chakra-ui/react';
import { isNull } from 'lodash-es';
import { FaChevronRight } from 'react-icons/fa';

function PDFViewer({
  label, url, children, labelProps,
}) {
  const childrenAvailable = !isNull(children);

  return (
    <>
      <HStack justifyContent="space-between" alignItems="center" marginBottom={childrenAvailable ? '16px' : 0}>
        <Text {...labelProps}>{label}</Text>
        <HStack alignItems="center">
          <Text fontSize="12px" color="gray.600">{`PDF ${url}`}</Text>
          <FaChevronRight />
        </HStack>
      </HStack>
      {children}
      {/* <PdfViewModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        url={data?.filePath}
      /> */}
    </>
  );
}

PDFViewer.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  labelProps: PropTypes.object,
};

PDFViewer.defaultProps = {
  children: null,
  labelProps: {},
};

export default PDFViewer;
