import { useStore, createStore } from 'zustand';
import { persist } from 'zustand/middleware';

const initState = {
  users: [],
  vessels: [],
  companies: [],
  masterCategories: [],
  subCategories: [],
  addToCrew: [],
  crewOnBoard: [],
  shiftNotificationsSent: true,
  isCrewOnBoardEnabled: false,
};

export const dataStore = createStore(
  persist(
    (set) => ({
      ...initState,
      setData: (users, vessels, companies, masterCategories, subCategories) => set(() => ({
        users, vessels, companies, masterCategories, subCategories,
      })),
      setCollectionData: (collection, data) => set(() => ({ [collection]: data })),
      setCategories: (masterCategories, subCategories) => set(() => (
        { masterCategories, subCategories })),
      resetUsers: async () => {
        set(initState);
      },
      setCompanies: (companies) => set(() => ({ companies })),
      setAddToCrew: (addToCrew) => set(() => ({ addToCrew })),
      setCrewOnBoard: (crewOnBoard) => set(() => ({ crewOnBoard })),
      setShiftNotificationsSent: (shiftNotificationsSent) => set(() => ({ shiftNotificationsSent })),
      setIsCrewOnBoardEnabled: (isCrewOnBoardEnabled) => set(() => ({ isCrewOnBoardEnabled })),
    }),
    {
      name: 'data_store',
    },
  ),
);

export const useDataStore = () => useStore(dataStore);
