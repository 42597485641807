import { NavLink, useLocation } from 'react-router-dom';
import {
  HStack, Link, Menu, MenuButton, MenuList, MenuItem, Button,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { isUndefined } from 'lodash-es';
import { FaChevronDown } from 'react-icons/fa';
import { getMainMenu } from '../config';
import { useUserStore } from '../../../store/user';

function DesktopMenu() {
  const intl = useIntl();
  const { pathname } = useLocation();

  const user = useUserStore();
  const menu = getMainMenu(intl, user?.role);
  const menuRef = useRef();

  return (
    <HStack spacing={3} ref={menuRef} flexWrap="wrap">
      {menu && menu.map((navItem, index) => {
        const isActive = pathname.includes(navItem.path);

        return navItem.subMenu ? (
          <Menu
            key={navItem.path || index}
          >
            <MenuButton
              as={Button}
              rightIcon={<FaChevronDown />}
              bg={isActive ? 'gray.400' : 'gray.100'}
              color={isActive ? 'gray.100' : '#677788'}
              rounded={8}
              px={4}
              py={2}

            >
              {navItem.label}
            </MenuButton>
            <MenuList>
              {navItem?.subMenu?.map((subItem) => (
                <MenuItem
                  key={subItem.path}
                  as={NavLink}
                  to={subItem.path}
                  onClick={() => {
                    // Removes the focus after a navigation change happened.
                    // dropdownRef.current.blur();
                  }}
                  _hover={{
                    textDecoration: 'none',
                    color: 'gray.100',
                    background: 'gray.400',
                  }}
                  _focus={{
                    textDecoration: 'none',
                    outline: 'none',
                    color: '#677788',
                  }}
                  _before={{
                    // color: 'gray.100',
                    borderColor: 'transparent',
                    borderRadius: 4,
                  }}
                  _after={{
                    position: 'absolute',
                    bottom: '0px',
                    left: 3,
                    right: 3,
                  }}
                  sx={{
                    '&.active': {
                      color: 'gray.100',
                      background: 'gray.400',
                    },
                    '&.active::after, &:hover::before': {
                      display: 'block',
                      color: 'black',
                    },
                    '&:hover': {
                      background: 'gray.400',
                      color: 'gray.100',
                    },
                    '&:focus::before': {
                      color: 'gray.100',
                      textDecoration: 'none',
                      display: 'block',
                      borderColor: 'gray.600',
                    },
                  }}

                >
                  {subItem.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : (
          <Link
            padding={0}
            key={navItem.path || index}
            to={navItem.path}
            as={NavLink}
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            fontSize={16}
            fontWeight={600}
            color="#677788"
            bg={isActive ? 'gray.400' : 'gray.100'}
            rounded={8}
            px={4}
            height="2.5rem"
            verticalAlign="middle"
            lineHeight="2.5rem"
            onClick={(event) => {
              if (!isUndefined(navItem.subMenu)) {
                // Removes the focus after a navigation change happened.
                // event.target.blur()

                // Does not allow to click on root menu option when there are submenus
                event.preventDefault();
              }
            }}
            _hover={{
              textDecoration: 'none',
              color: 'gray.100',
              cursor: 'pointer',
              background: 'gray.400',
            }}
            _focus={{
              textDecoration: 'none',
              outline: 'none',
              color: 'black',
            }}
            _before={{
              content: '""',
              display: 'none',
              color: 'gray.100',
              borderColor: 'transparent',
              borderRadius: 4,
            }}
            _after={{
              content: '""',
              position: 'absolute',
              bottom: '0px',
              left: 3,
              right: 3,
            }}
            sx={{
              '&.active': {
                color: 'gray.100',
              },
              '&.active::after, &:hover::before': {
                display: 'block',
                color: 'black',
              },
              '&:hover': {
                background: 'gray.400',
                color: 'gray.100',
              },
              '&:focus::before': {
                textDecoration: 'none',
                display: 'block',
                borderColor: 'gray.600',
              },
            }}
          >
            <span>{navItem.label}</span>
          </Link>
        );
      })}
    </HStack>
  );
}

export default DesktopMenu;
