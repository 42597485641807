import {
  ref, uploadBytes, getDownloadURL, deleteObject, getBytes,
} from 'firebase/storage';
import { isUndefined } from 'lodash-es';
import { storage } from '../../config/firebase';
// eslint-disable-next-line import/no-cycle
import { deleteFileFromDocument } from './firestore/documents';

// eslint-disable-next-line import/prefer-default-export
export const uploadFile = async ({ file, path }) => {
  const fileName = file?.name;

  if (!fileName) {
    throw new Error('File name is undefined or null.');
  }

  const storageRef = ref(storage, `${path}/${fileName}`);

  await uploadBytes(storageRef, file);

  const url = await getDownloadURL(storageRef);

  return url;
};

export const uploadPdf = async ({ file, path, fileName }) => {
  const storageRef = ref(storage, `${path}/${fileName}`);

  await uploadBytes(storageRef, file);

  const url = await getDownloadURL(storageRef);

  return url;
};

export const deleteFile = async ({ url, attachedDocumentId }) => {
  const fileRef = ref(storage, url);
  await deleteObject(fileRef);

  if (!isUndefined(attachedDocumentId)) {
    await deleteFileFromDocument({ file: url, documentId: attachedDocumentId });
  }
};

export const downloadFile = async ({ url }) => {
  const httpReference = ref(storage, url);

  await getBytes(httpReference);

  // const xhr = new XMLHttpRequest();
  // xhr.responseType = 'blob';
  // // eslint-disable-next-line no-unused-vars
  // xhr.onload = (event) => {
  //   // eslint-disable-next-line no-unused-vars
  //   const blob = xhr.response;
  // };
  // xhr.open('GET', url);
  // xhr.send();
};

export const getFileByPath = async ({ path, fileName }) => {
  const fileRef = ref(storage, `${path}/${fileName}`);

  const buffer = await getBytes(fileRef);
  const html = new TextDecoder().decode(buffer);

  return html;
};
