import { Text as ChakraText } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function Text({ children, ...props }) {
  const { fontSize } = props;
  return (
    <ChakraText fontSize="16px" paddingTop={fontSize >= 20 ? '20px' : '0px'} lineHeight="20px" width="100%" {...props}>{children}</ChakraText>
  );
}

Text.propTypes = {
  children: PropTypes.string,
  fontSize: PropTypes.number,
};

Text.defaultProps = {
  children: 'Text',
  fontSize: 16,
};

export default Text;
