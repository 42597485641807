import {
  Box, Container, HStack,
} from '@chakra-ui/react';
import cr from '../../assets/cr.svg';

function Footer() {
  return (
    <Box
      paddingY={2}
      paddingX={10}
      height="56px"
    >
      <Container
        maxWidth="container.2xl"
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        paddingY={2}
      >
        <HStack>
          <img src={cr} width={14} alt="" />
          <span style={{ color: '#677788' }}>ALDA</span>

        </HStack>
        <div style={{ color: '#677788', display: 'flex', flexDirection: 'row' }}>
          <span>FAQ</span>
          <span style={{ marginLeft: '4px', marginRight: '4px' }}> / </span>
          <span style={{ marginRight: '4px' }}>License </span>
        </div>
      </Container>
    </Box>
  );
}

Footer.propTypes = {};

export default Footer;
