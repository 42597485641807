/* eslint-disable no-console */
import API from './_instance';
import { endpoints } from './constants';

export const fetchAssignments = async ({
  vesselId, searchValue, itemsPerPage = 25, currentPage = 0, isAscendingOrder = true, orderBy = 'submittedAt',
}) => {
  const { data } = await API.get(endpoints.assignments, {
    params: {
      vesselId,
      searchValue,
      itemsPerPage,
      currentPage,
      isAscendingOrder,
      sortBy: orderBy,
    },
  });

  return data;
};

export const fetchHiddenAssignments = async ({
  vesselId, searchValue, itemsPerPage = 25, currentPage = 0, isAscendingOrder = true, orderBy = 'submittedAt',
}) => {
  const { data } = await API.get(endpoints.hiddenAssignments, {
    params: {
      vesselId,
      searchValue,
      itemsPerPage,
      currentPage,
      isAscendingOrder,
      sortBy: orderBy,
    },
  });

  return data;
};

export const fetchCompletedAssignments = async ({
  vesselId, searchValue, itemsPerPage = 25, currentPage = 0, isAscendingOrder = true, orderBy = 'submittedAt',
}) => {
  const { data } = await API.get(endpoints.completedAssignments, {
    params: {
      vesselId,
      searchValue,
      itemsPerPage,
      currentPage,
      isAscendingOrder,
      sortBy: orderBy,
    },
  });

  return data;
};

export const fetchAssignmentDetails = async ({
  assignmentId,
}) => {
  const response = await API.get(endpoints.assignment, {
    params: {
      assignmentId,
    },
  });

  if (response.status !== 200) {
    throw new Error(response.data);
  }

  return response.data;
};

export const fetchAssignmentImages = async (trainingResultId) => {
  if (!trainingResultId) {
    throw new Error('Tried to fetch assignment images without a trainingResultId.');
  }

  const response = await API.get(endpoints.assignmentImages, {
    params: {
      trainingResultId,
    },
  });

  if (response.status !== 200) {
    throw new Error(response.data);
  }

  return response.data;
};

export const updateAssignmentDetails = async ({
  status = '0',
  responsibleId = undefined,
  requestedById = undefined,
  assignmentId,
  isVisible,
}) => {
  const response = await API.post(endpoints.assignment, {
    status,
    responsibleId,
    requestedById,
    assignmentId,
    isVisible,
  });

  if (response.status !== 200) {
    throw new Error(response.data);
  }

  return response.data;
};

// function to get assignment commemments
export const fetchAssignmentComments = async ({
  assignmentId,
}) => {
  const response = await API.get(endpoints.assignmentComments, {
    params: {
      assignmentId,
    },
  });

  if (response.status !== 200) {
    throw new Error(response.data);
  }

  return response.data;
};

// function to post assignment commemments
export const postAssignmentComments = async ({
  assignmentId, comment, userID, responsiblePersonId, requestedById,
}) => {
  const response = await API.post(endpoints.assignmentComments, {
    assignmentId,
    comment,
    userID,
    responsiblePersonId,
    requestedById,
  });

  if (response.status !== 200) {
    throw new Error(response.data);
  }

  return response.data;
};

export const sendEmailToResponsible = async (userId, assignmentId) => {
  try {
    const response = await API.post(endpoints.assignmentSendEmail, { userId, assignmentId });
    const { data } = response || {};
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error sending email:', error);
    throw error;
  }
};

export const sendEmailToResponsibleOnComment = async (userId, assignmentId, commenterName, requestedById) => {
  try {
    const response = await API.post(endpoints.assignmentSendEmailToResponsibleOnComment, {
      userId, assignmentId, commenterName, requestedById,
    });
    const { data } = response || {};
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error sending email:', error);
    throw error;
  }
};

// a function to send email toresponsible on status change
export const sendEmailOnStatusChange = async (userId, assignmentId, status, requestedById) => {
  try {
    const response = await API.post(endpoints.assignmentSendEmailStatusChange, {
      userId, assignmentId, status, requestedById,
    });
    const { data } = response || {};
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error sending email:', error);
    throw error;
  }
};

const { REACT_APP_ENVIRONMENT } = process.env;

export const addLog = async (log) => {
  try {
    const oldActionLog = JSON.parse(log.actionLog);
    const actionLog = { ...oldActionLog, environment: REACT_APP_ENVIRONMENT };
    const finalLog = { ...log, source: 'webadmin', actionLog: JSON.stringify(actionLog) };

    const response = await API.post(endpoints.logsAdd, finalLog);
    const { data } = response || {};
    return data;
  } catch (error) {
    console.error('Error adding log:', error);
    throw error;
  }
};

export const fetchLogs = async () => {
  try {
    const response = await API.get(endpoints.logsGet);
    const { data } = response || {};
    return data;
  } catch (error) {
    console.error('Error getting logs:', error);
    throw error;
  }
};

export const createNewUserSql = async ({
  email, results, fcmTokens, createdAt, id, ...restProps
}) => {
  try {
    const response = await API.post(endpoints.users, {
      ...restProps, email, results, fcmTokens, createdAt, id,
    });
    const { data } = response || {};
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error creating new user:', error);
    throw error;
  }
};

export const updateUserSql = async ({
  id, ...data
}) => {
  try {
    const response = await API.put(`${endpoints.users}/${id}`, { ...data });
    const { data: updatedUser } = response || {};
    return updatedUser;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating user:', error);
    throw error;
  }
};

export const transferUser = async (params) => {
  try {
    const response = await API.patch(`${endpoints.users}`, params);
    const { data } = response || {};

    if (data.error) {
      throw new Error(data.error);
    }

    if (data.status === 400) {
      throw new Error(data.message);
    }

    if (data.status === 500) {
      throw new Error(data.message);
    }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error transfering user:', error);
    throw error;
  }
};

export const updateSettings = async ({
  type, value,
}) => {
  try {
    const response = await API.patch(`${endpoints.settings}`, { type, value });
    const { data } = response || {};
    // console.log(setting.type, 'Setting updated successfully to', setting.value, ' api code');
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating settings:', error);
    throw error;
  }
};

export const fetchSettings = async () => {
  try {
    const response = await API.get(endpoints.settings);
    const { data } = response || {};
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching settings:', error);
    throw error;
  }
};

export const fetchUserFcmToken = async (userId) => {
  try {
    const response = await API.get(`${endpoints.userFcmToken}/${userId}`);
    const { data } = response || {};
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching settings:', error);
    throw error;
  }
};

export const fetchDrills = async () => {
  try {
    const response = await API.get(endpoints.drills);
    const { data } = response || [];
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching drills:', error);
    throw error;
  }
};

export const fetchUserExistsBySsn = async (ssn) => {
  try {
    const response = await API.get(`${endpoints.users}/${ssn}`);
    const { data } = response;
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching user by ssn:', error);
    throw error;
  }
};

export const resignUser = async (userId) => {
  try {
    const response = await API.delete(`${endpoints.users}/${userId}`);
    const { data } = response;

    if (response.status !== 200) {
      throw new Error(data);
    }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error deleting user by userId:', error);
    throw error;
  }
};
