import { BASIC_AUTH } from '../constants';
import API from './_instance';

const translations = {
  'en-GB': import('../../locale/en-GB/messages.json'),
  'da-DK': import('../../locale/da-DK/messages.json'),
  IS: import('../../locale/isl/messages.json'),
  PL: import('../../locale/pl/messages.json'),
};

// eslint-disable-next-line import/prefer-default-export
export const fetchTranslations = async (locale) => {
  const genericMessages = await translations[locale];

  const { data } = await API.get('/Lang', {
    params: {
      lang: locale,
    },
    headers: {
      Authorization: BASIC_AUTH,
    },
  });

  const merged = { ...genericMessages, ...data };

  return merged;
};
