import { useStore, createStore } from 'zustand';
import { persist } from 'zustand/middleware';

const initState = {
  language: 'IS',
  itemsPerPage: 100,
};

export const appStore = createStore(
  persist(
    (set) => ({
      ...initState,
      setLanguage: (language) => set(() => ({ language })),
      setItemsPerPage: (itemsPerPage) => set(() => ({ itemsPerPage })),
    }),
    {
      name: 'app_store',
    },
  ),
);

export const useAppStore = () => useStore(appStore);
