export default {
  Input: {
    baseStyle: {
      borderRadius: 0,
      fontSize: '16px',
      padding: 0,
    },
    variants: {
      default: {
        // fontFamily: 'Arial',
        fontSize: '16px',
        borderBottomWidth: '1px',
        fontWeight: '400',
        borderBottomColor: 'gray.500',
      },
    },
  },
};
