import { useStore, createStore } from 'zustand';
import { persist } from 'zustand/middleware';

const initState = {
  isAuthenticated: false,
  uid: null,
  refreshToken: null,
  accessToken: null,
  tokenExpiration: null,
  tokenExpirationTime: null,
  email: '',
};

export const authStore = createStore(
  persist(
    (set) => ({
      ...initState,
      setIsAuthenticated: (isAuthenticated) => set(() => ({ isAuthenticated })),
      setAuthData: (auth) => set(() => ({ ...auth })),
      updateAccessToken: (newToken) => set((state) => ({ ...state, accessToken: newToken })),
      updateRefreshToken: (newToken) => set((state) => ({ ...state, refreshToken: newToken })),
      updateTokenExpiration: (newExpiration) => set((state) => ({ ...state, tokenExpiration: newExpiration })),
      handleLogout: async () => {
        set(initState);
      },
    }),
    {
      name: 'auth_store',
    },
  ),
);

export const useAuthStore = () => useStore(authStore);
