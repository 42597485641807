import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, Checkbox as CheckboxNB, HStack, Text,
} from '@chakra-ui/react';
import { FaCheck, FaXmark } from 'react-icons/fa6';
import { useController, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

function Checkbox({
  children, name, controlAll, isDisabled, required, ...props
}) {
  const intl = useIntl();
  const requiredErrMessage = intl.formatMessage({ id: 'ERR_FIELD_REQUIRED', defaultMessage: 'This field is required.' });
  const { getValues } = useFormContext();

  const disabled = getValues('formDisabled') || isDisabled;

  // eslint-disable-next-line no-unused-vars
  const [isInputDisabled, setIsInputDisabled] = useState(disabled);

  const { field, fieldState: { error } } = useController({
    name, defaultValue: false, rules: { required },
  });

  return (
    <FormControl defaultValue={false} key={field?.name} id={field?.id}>
      <HStack space="80px" flex={1} marginTop="8px">
        <CheckboxNB
          isReadOnly
          // onLayout={() => {
          //
          //   setIsInputDisabled(disabled);
          // }}
          key={field.name}
          name={field.name}
          _disabled={{
            _checked: {
              '& .chakra-checkbox__control': { background: field.name === 'Ekki í lagi' ? '#c25757' : '#61a158', borderColor: field.name === 'Ekki í lagi' ? '#c25757' : '#61a158', color: '#FFFFFF' },
            },
          }}
          _checked={{
            '& .chakra-checkbox__control': { background: field.name === 'Ekki í lagi' ? '#c25757' : '#61a158', borderColor: field.name === 'Ekki í lagi' ? '#c25757' : '#61a158', color: '#FFFFFF' },
          }}
          _invalid={{
            '& .chakra-checkbox__control': { background: '#c25757', borderColor: '#c25757', color: '#FFFFFF' },
          }}
          size="lg"
          iconColor="white"
          colorScheme="primary"
          onChange={field.onChange}
          isDisabled={isInputDisabled}
          isChecked={field.value}
          isInvalid={!field.value}
          icon={!field.value ? <FaXmark color="#fffff" /> : <FaCheck color="#fffff" />}
          {...props}
        />
        {children}
      </HStack>
      {error && <Text variant="error">{requiredErrMessage}</Text>}
    </FormControl>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  controlAll: PropTypes.bool,
  isDisabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
  required: PropTypes.bool,
};

Checkbox.defaultProps = {
  controlAll: false,
  isDisabled: false,
  required: false,
};

export default Checkbox;
