export const pathNames = {
  root: '/',
  wildcard: '*',
  signIn: '/sign-in',
  home: '/home',
  companies: '/companies',
  vessels: '/vessels',
  users: '/users',
  profile: '/profile',
  documents: '/documents',
  reports: '/reports',
  data: '/data',
  user: '/user',
  allUsers: '/users/all',
  alertsCenter: '/alerts-center',
  feedback: '/feedback',
  assignments: '/assignments',
  auditlogs: '/auditlogs',
  information: '/information',
  settings: '/settings',
  companyOnboard: '/company-onboard',
  trainingSchedule: '/training-schedule',
  cms: '/cms',
};

export const rootPathNames = {
  signIn: '/sign-in/*',
  home: '/home/*',
  companies: '/companies/*',
  vessels: '/vessels/*',
  users: '/users/*',
  profile: '/profile/*',
  documents: '/documents/*',
  reports: '/reports/*',
  data: '/data/*',
  user: '/user/*',
  alertsCenter: '/alerts-center/*',
  feedback: '/feedback/*',
  assignments: '/assignments/*',
  auditlogs: '/auditlogs/*',
  information: '/information/*',
  settings: '/settings/*',
  companyOnboard: '/company-onboard/*',
  cms: '/cms/*',
};
