import { createElement } from 'react';
import { isArray, isUndefined } from 'lodash-es';
import { Text } from '@chakra-ui/react';
import { generateRandomString } from '../../../utils/utils';

const createElementFromJson = (json, components, config) => {
  const { Resource } = components;
  const {
    id,
    key,
    type,
    resource,
    props = {},
    children,
  } = json;

  if (!type) {
    return <Text fontWeight="bold" color="primary.500">JSON ERROR: No type provided for component.</Text>;
    // throw new Error('No type was passed!')
  }
  if (!components?.[type]) {
    return <Text>{`JSON ERROR: No component found for this type: ${type}`}</Text>;
    // throw new Error(`No component found for this type: ${type}`);
  }

  if (!isUndefined(children) && !isArray(children) && (type !== 'Text')) {
    return <Text>{`JSON ERROR: Children of component type: ${type} must be an array.`}</Text>;
  }

  if (resource) {
    return (
      <Resource
        key={key ?? id}
        json={json}
      />
    );
  }

  // eslint-disable-next-line no-unused-vars
  const {
    width, maxWidth, height, maxHeight, minWidth, minHeight, display, ...rest
  } = props;

  return createElement(
    components[type],
    {
      ...rest,
      key: generateRandomString({ length: 6 }),
      id,
      'data-ad': id,
      _config: config,
    },
    isArray(children) && children.length > 0
      ? children.map((child) => createElementFromJson(child, components, config))
      : children,
  );
};

export default createElementFromJson;
