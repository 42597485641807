/* eslint-disable react/jsx-filename-extension */
import { isEmpty } from 'lodash-es';
import {
  BiHome, BiBuildings, BiUser, BiFile, BiWater, BiData, BiCalendar,
} from 'react-icons/bi';
import { pathNames } from '../../config/pathNames';
import messages from './messages';
import { USER_ROLES } from '../../services/constants';

export const hiddenMenuRoutes = [
  pathNames.signIn,
  pathNames.companyOnboard,
];

// NOTE: Add roles here for Menu
const {
  SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER,
} = USER_ROLES;

const mainMenu = (intl) => ([
  {
    path: pathNames.home,
    label: intl.formatMessage(messages.home),
    icon: BiHome,
    roles: [],
  },
  {
    path: `${pathNames.data}/powerbi`,
    label: intl.formatMessage(messages.PowerBI),
    icon: BiData,
    roles: [],
  },
  // {
  //   path: '/sentry',
  //   label: intl.formatMessage(messages.PowerBI),
  //   icon: BiData,
  //   roles: [],
  // },
  // {
  //   path: pathNames.data,
  //   label: intl.formatMessage(messages.data),
  //   icon: BiData,
  //   roles: [],
  //   subMenu: [
  //     {
  //       label: intl.formatMessage(messages.safetyInductions),
  //       path: `${pathNames.data}/safety-inductions`,
  //     },
  //     {
  //       label: intl.formatMessage(messages.trainingDrills),
  //       path: `${pathNames.data}/training-drills`,
  //     },
  //     {
  //       label: intl.formatMessage(messages.inspections),
  //       path: `${pathNames.data}/inspections`,
  //     },
  //     {
  //       label: intl.formatMessage(messages.CrewCall),
  //       path: `${pathNames.data}/crewcall`,
  //     },
  //     {
  //       label: intl.formatMessage(messages.PowerBI),
  //       path: `${pathNames.data}/powerbi`,
  //     },
  //   ],
  // },
  {
    path: pathNames.companies,
    label: intl.formatMessage(messages.companies),
    icon: BiBuildings,
    roles: [SUPER_ADMIN],
  },
  {
    path: pathNames.vessels,
    label: intl.formatMessage(messages.vessels),
    icon: BiWater,
    roles: [SUPER_ADMIN, COMPANY_ADMIN, COMPANY_OWNER],
  },
  {
    path: pathNames.allUsers,
    label: intl.formatMessage(messages.users),
    icon: BiUser,
    roles: [SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER],
  },
  {
    path: pathNames.reports,
    label: intl.formatMessage(messages.reports),
    icon: BiFile,
    roles: [SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER],
  },
  {
    path: pathNames.assignments,
    label: intl.formatMessage(messages.assignments),
    icon: BiBuildings,
    roles: [SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER],
  },
  {
    path: pathNames.documents,
    label: intl.formatMessage(messages.documents),
    icon: BiFile,
    roles: [SUPER_ADMIN],
  },
  {
    path: pathNames.cms,
    label: intl.formatMessage(messages.cms),
    icon: BiData,
    roles: [SUPER_ADMIN, COMPANY_ADMIN, COMPANY_OWNER],
  },
  {
    path: pathNames.trainingSchedule,
    label: intl.formatMessage(messages.trainingSchedule),
    icon: BiCalendar,
    roles: [SUPER_ADMIN, CAPTAIN, COMPANY_ADMIN, COMPANY_OWNER],
  },
  // {
  // path: pathNames.auditlogs,
  // label: intl.formatMessage(messages.auditlogs),
  // icon: BiFile,
  // roles: [SUPER_ADMIN],
  // },
]);

export const getMainMenu = (intl, userRole) => {
  const role = USER_ROLES[userRole];

  const menu = mainMenu(intl);

  const retVal = menu.filter((m) => m.roles.includes(role) || isEmpty(m.roles));

  return retVal;
};

export const getUserMenu = () => ([]);
