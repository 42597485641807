import axios from 'axios';
import { resourceTimeout } from '../constants';
import { authStore } from '../../store/auth';
import { userStore } from '../../store/user';

import { auth } from '../../config/firebase';

const { REACT_APP_AZURE_CLOUD_API } = process.env;

if (!REACT_APP_AZURE_CLOUD_API) {
  throw new Error('No setting for Azure was found (CRITICAL)');
}

const API = axios.create({ baseURL: REACT_APP_AZURE_CLOUD_API, timeout: resourceTimeout });

const handleRequest = async (config) => {
  const user = auth.currentUser;

  if (user) {
    let token = await user.getIdToken();
    let { tokenExpirationTime } = authStore.getState();

    if (!token || Date.now() >= tokenExpirationTime - 60000) {
      const idTokenResult = await user.getIdTokenResult(true);
      token = idTokenResult.token;
      tokenExpirationTime = new Date(idTokenResult.expirationTime).getTime();

      authStore.setState({
        accessToken: token,
        tokenExpirationTime,
      });
    }

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    const authPersistanceStore = authStore.getState();
    const userPersistanceStore = userStore.getState();

    authPersistanceStore.handleLogout();
    userPersistanceStore.resetUser();
  }
  return config;
};

const handleError = async (error) => {
  const status = error?.request?.status;

  if (status === 403) {
    const authPersistanceStore = authStore.getState();
    const userPersistanceStore = userStore.getState();

    // logout user
    authPersistanceStore.handleLogout();
    userPersistanceStore.resetUser();
    // reload page
    window.location.reload();
  }

  return { status: error?.request?.status, data: error?.response?.data };
};

API.interceptors.request.use(handleRequest);
API.interceptors.response.use(
  (response) => response,
  (error) => handleError(error),
);

export default API;
