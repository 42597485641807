import PropTypes from 'prop-types';
import { HStack, Image, useBreakpointValue } from '@chakra-ui/react';
import { useAppStore } from '../../store/app';

function LanguageOption({ isSelected, flag, value }) {
  const isMdBreakpoint = useBreakpointValue({ base: false, md: true });
  const { setLanguage } = useAppStore();

  const selectLanguage = () => setLanguage(value);

  return (
    <HStack
      key={value}
      onClick={selectLanguage}
      // eslint-disable-next-line no-nested-ternary
      bg={!isSelected ? 'transparent'
        : !isMdBreakpoint ? 'blue.500' : 'white'}
      borderRadius={4}
      padding="2px"
      _hover={{
        cursor: 'pointer',
      }}
    >
      <Image width={6} src={flag} key={flag} />
    </HStack>
  );
}

LanguageOption.propTypes = {
  flag: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default LanguageOption;
