import { Timestamp } from 'firebase/firestore';

// eslint-disable-next-line import/prefer-default-export
export const getDataFromSnapsArray = (snap) => {
  const retVal = [];

  snap.forEach((d) => {
    const data = d.data();
    const { id } = d;

    const res = {
      id, ...data,
    };

    retVal.push(res);
  });

  return retVal;
};

export const convertTimestampToPrettyDate = (timestamp, locale = 'is-IS') => {
  if (!(timestamp instanceof Timestamp)) {
    throw new Error('Input must be a Firestore Timestamp object.');
  }

  const date = timestamp.toDate();

  // Options for formatting the date
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  // Convert the date to a pretty format
  const prettyDate = date.toLocaleDateString(locale, options);

  return prettyDate;
};

export const secondsToDateTime = (seconds, locale = 'en-US') => {
  const date = new Date(seconds * 1000); // Convert seconds to milliseconds

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const prettyDate = date.toLocaleDateString(locale, options);

  return prettyDate;
};

export const isFirebaseStorageImage = ({ url }) => url.includes('firebasestorage');

export const getStoragePathFromUrl = (fullUrl) => {
  const urlToken = fullUrl.split('?');
  const url = urlToken[0].split('/');
  const firebaseFilePath = url[url.length - 1];
  const firebasePath = decodeURIComponent(firebaseFilePath);

  // const firebasePathWithWhiteSpaces = url[url.length - 1].replaceAll('%2F', '/');
  // const firebasePath = firebasePathWithWhiteSpaces.replaceAll(/%20/g, ' ');

  return firebasePath;
};
