/* eslint-disable no-unused-vars */
// import { Image as ChakraImage } from '@chakra-ui/react';
import PropTypes from 'prop-types';
// import { isString } from 'lodash-es';

function Image({
  children, url, width, height, alt, resizeMode, ...props
}) {
  // const heightProp = isString(height) ? parseInt(height.slice(0, -2), 10) : height;
  // const widthProp = isString(width) ? parseInt(width.slice(0, -2), 10) : width;

  // Held þessu inni ef við viljum nota þetta seinna

  return (
    // <ChakraImage
    //   src={url}
    //   width={widthProp}
    //   height={heightProp}
    //   backgroundSize={resizeMode}
    //   rounded="8px"
    //   marginY="8px"
    //   {...props}
    // />
    null
  );
}

Image.propTypes = {
  children: PropTypes.string,
  url: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alt: PropTypes.string,
  resizeMode: PropTypes.string,
};

Image.defaultProps = {
  children: 'FileUpload',
  width: '80px',
  height: '80px',
  alt: 'imageAlt',
  resizeMode: 'cover',
};

export default Image;
