import { encode as btoa } from 'base-64';

export const resourceTimeout = 10000;
export const AZURE_API_URL = 'https://api20221205215003.azurewebsites.net/API';

const username = 'FleetTest';
const password = '12345678';

export const BASIC_AUTH = `Basic ${btoa(`${username}:${password}`)}`;

export const AXIOS_CONNECTION_ABORTED_ERROR_CODE = 'ECONNABORTED';
export const AXIOS_TIMEOUT_ERROR_CODE = 'ETIMEDOUT';

export const USER_ROLES = {
  SUPER_ADMIN: {
    accessLevel: 0,
    key: 'SUPER_ADMIN',
  },
  PARTNER: {
    accessLevel: 1,
    key: 'PARTNER',
  },
  COMPANY_OWNER: {
    accessLevel: 2,
    key: 'COMPANY_OWNER',
  },
  COMPANY_ADMIN: {
    accessLevel: 3,
    key: 'COMPANY_ADMIN',
  },
  CAPTAIN: {
    accessLevel: 4,
    key: 'CAPTAIN',
  },
  UNASSIGNED: {
    accessLevel: -1,
    key: 'UNASSIGNED',
  },
};

export const ONE_SIGNAL_API_URL = 'https://onesignal.com/api/v1';
export const OS_API_KEY = 'MzkzZTUwOTktNzFiNS00YWI1LTkxZTctY2YxZmRiMTNhZDA0';
export const OS_APP_ID = '698596f3-f7af-4db0-9979-132f7a3300fa';
