import {
  useToast as useChakraToast, Box, VStack, HStack, CircularProgress,
} from '@chakra-ui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import Toast from '../components/Toast/Toast';
import { AXIOS_TIMEOUT_ERROR_CODE, AXIOS_CONNECTION_ABORTED_ERROR_CODE } from '../services/constants';
import toastMessages from '../messages/toast';

export const getDefaultToastProps = (intl) => ({
  title: intl.formatMessage(toastMessages.genericErrorHeading),
  description: intl.formatMessage(toastMessages.genericErrorDescription),
  isClosable: true,
  status: 'error',
  timeout: 4000,
  id: uuidv4(),
});

const useToast = () => {
  const intl = useIntl();
  const chakraToast = useChakraToast();
  const toastIdRef = React.useRef();

  return (toastConfig, error) => {
    if (
      error?.code === AXIOS_TIMEOUT_ERROR_CODE
      || error?.code === AXIOS_CONNECTION_ABORTED_ERROR_CODE
    ) {
      return chakraToast({
        render: () => (
          <Toast
            title={intl.formatMessage(toastMessages.requestTimeoutHeading)}
            description={intl.formatMessage(toastMessages.requestTimeoutDescription)}
            status="error"
          />
        ),
      });
    }

    if (toastConfig?.status === 'mutate') {
      toastIdRef.current = chakraToast({
        timeout: 20000,
        render: () => (
          <Toast
            isAction
            title={intl.formatMessage(toastMessages.genericAction)}
          />
        ),
      });
    } else {
      const { title, description, status } = toastConfig;

      if (toastIdRef.current) {
        chakraToast.update(toastIdRef.current, {
          render: () => (
            <Toast
              title={title}
              description={description}
              status={status}
            />
          ),
        });

        return null;
      }

      toastIdRef.current = chakraToast({
        render: () => (
          <Toast
            title={title}
            description={description}
            status={status}
          />
        ),
      });
    }

    return toastIdRef.current;
  };
};

export default useToast;
