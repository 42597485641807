import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
import {
  Icon,
  Box,
  Button,
  Slide,
  Portal,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { getMainMenu } from '../config';
import MenuItem from '../components/MenuItem';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';
import { useUserStore } from '../../../store/user';

function MobileMenu({ isOpen, onToggle }) {
  const user = useUserStore();

  const intl = useIntl();
  const menu = getMainMenu(intl, user?.role);

  return (
    <Portal>
      <Slide in={isOpen} direction="left">
        <Box
          display="block"
          background="white"
          boxShadow="2xl"
          position="fixed"
          top={0}
          right={0}
          bottom={0}
          left={0}
        >
          <HStack
            paddingY={2}
            paddingX={4}
            backgroundColor="white"
            justifyContent="space-between"
            borderBottom="1px"
            borderColor="gray.100"
          >
            <Button
              onClick={onToggle}
              variant="ghost"
              aria-label={intl.formatMessage({ id: 'CLOSE_MOBILE_MENU', defaultMessage: 'Close Mobile Menu' })}
            >
              <Icon color="blue.500" as={FaTimes} />
            </Button>
            <LanguageSwitcher />
          </HStack>
          <VStack
            spacing={0}
            alignItems="stretch"
          >
            {menu.map((menuItem, index) => (
              <MenuItem
                key={menuItem.path || index}
                to={menuItem.path}
                onToggle={onToggle}
                {...menuItem}
              />
            ))}
          </VStack>
        </Box>
      </Slide>
    </Portal>
  );
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default MobileMenu;
