/* eslint-disable no-console */
import API from './_instance';
import { endpoints } from './constants';

export const createNewVesselSql = async (newVessel) => {
  try {
    const response = await API.post(endpoints.vessels, newVessel);
    const { data } = response || {};
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error creating new vessel in SQL:', error);
    throw error;
  }
};

export const updateVesselSql = async (updatedVessel) => {
  try {
    if (!updatedVessel.id) throw new Error('No vessel ID provided.');

    const response = await API.put(`${endpoints.vessels}/${updatedVessel.id}`, updatedVessel);
    const { data: updatedVesselResponse } = response || {};
    return updatedVesselResponse;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating vessel in SQL:', error);
    throw error;
  }
};

export const fetchVessel = async (vesselId) => {
  try {
    if (!vesselId) return {};

    const response = await API.get(`${endpoints.vessels}/${vesselId}`);
    const { data } = response;

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching vessel by vesselId:', error);
    throw error;
  }
};

export const updateVesselOperativeSql = async (vesselId, operative) => {
  try {
    if (!vesselId) throw new Error('No vessel ID provided.');

    const response = await API.post(`${endpoints.vessels}/${vesselId}`, { operative });
    const { data: updatedVesselResponse } = response || {};
    return updatedVesselResponse;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating vessel operative in SQL:', error);
    throw error;
  }
};
